.Button {
    font-size: 0.8rem;
    align-self: center;
    border-radius: 100px;
    border-color: transparent;
    padding: 4px 10px;
    margin-left: 2px;
    margin-right: 2px;
    min-width: 75px;
}

.Button:hover {
    opacity: 0.8;
    border-color: transparent;
    cursor: pointer;
}

.Button:hover:active {
    background-color: #2142b2 !important;
}

.Button:active {
    background-color: #2142b2 !important;
}

.Button:focus {
    background-color: #2142b2;
}

.Wide {
    padding: 10px 50px;
    width: 100%;
}

.Blue,
.BlueOutline:hover,
.Bright:hover {
    background-color: #2142b2;
    color: #ffffff;
}

.BlueOutline,
.Blue:hover {
    /* border: 1px solid #2142b2; */
    background-color: #ffffff;
    color: #2142b2;
}

.Black,
.BlackOutline:hover {
    /* background-color: #242a3d;
    color: white; */
    background-color: #242a3d;
    color: aliceblue;
}

.Black:hover,
.BlackOutline {
    /* background-color: #dadadf;
    color: #242a3d;
    border-color: #242a3d; */
    background-color: aliceblue;
    color: #242a3d;
}

.Bright {
    background-color: #2ed9ff;
    color: #2142b2;
}