.SearchBar input {
    border-radius: 20px;
    color: #242a3d;
    padding-left: 40px;
    margin-bottom: 16px;
}

.SearchIcon {
    position: absolute;
    font-size: 1.2rem;
    padding: 9px;
    padding-left: 15px;
}

.SearchIcon:hover {
    cursor: default;
    color: #cfced3;
}