.Row {
    height: calc(100vh - 81px);
}

.Input {
    background: #ffffff;
    border: 1px solid #f0f1f9;
    box-sizing: border-box;
    border-radius: 2px;
    color: #7177a1;
    text-align: center;
    padding: 10px;
}

.Header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2142b2;
}

.Link {
    text-decoration: none;
    text-align: center;
    display: block;
    margin: auto;
}

.Link span {
    color: #ff9254;
}

.Container {
    padding: 30px 10px;
}

.SignInCol {
    height: 320px;
}

.SignUpCol {
    height: 375px;
}